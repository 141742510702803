// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import {
  catchError, delay, map, retryWhen, shareReplay, take,
} from 'rxjs/operators';
import { ApiService } from './api.service';
import { ProductCode, MarketingOrder, TemplateInstance } from '../models';
import { DummyText } from '../constants';
import { MarketingOrderService } from './marketing-order.service';

/**
 * A lookup[ table, maps product code to default thumbnail images when none is
 * present in the template.
 */
const defaultURLMap = {
};
defaultURLMap[ProductCode.FLYER] = '/assets/images/thumbnails/flyer-default.png';
defaultURLMap[ProductCode.BROCHURE] = '/assets/images/thumbnails/brochure-default.png';
defaultURLMap[ProductCode.JUST_LISTED_POSTCARD] = '/assets/images/thumbnails/just-listed-postcard-default.png';
defaultURLMap[ProductCode.JUST_SOLD_POSTCARD] = '/assets/images/thumbnails/just-sold-postcard-default.png';
defaultURLMap[ProductCode.SILVER_ENVELOPE_MAILER] = '/assets/images/thumbnails/mailer-default.png';
defaultURLMap[ProductCode.PRINT_ADVERTISING] = '/assets/images/thumbnails/advertising-default.png';
defaultURLMap[ProductCode.SOCIAL_MEDIA_BANNER] = '/assets/images/thumbnails/social-media-banner-default.png';
defaultURLMap[ProductCode.CLIENT_REPORT] = '/assets/images/thumbnails/client-report-default.png';

export class PreviewPdfRes {
  previewPdfUrl: string;

  constructor(model?: Partial<PreviewPdfRes>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}

export enum exportType { // for previewPdfUrl api
  TEMPLATE_SPEC = 'Template-Spec',
}
@Injectable()
export class TemplateService {
  resource = 'template-info';
  disableSendForApprovalBtn:boolean = false;
  private readonly productTemplateCache: { [marketingOrderId: string]: { [productId: string] : Observable<TemplateInstance[]> } } = {};

  constructor(
    private apiService: ApiService,
    private orderService: MarketingOrderService,
  ) { }

  /**
   * Returns the previewPdfUrl related to a given templateCode
   * @param templateCode The templateCode being requested
   */
  async getTemplatePreviewPdfUrl(templateCode: string): Promise<PreviewPdfRes> {
    const url = `/template-specs/previewpdf?codes=${templateCode}&exportType=${exportType.TEMPLATE_SPEC}`;
    return firstValueFrom(this.apiService.get(`${url}`));
  }
  /**
   * This Method tries to check if the object is found on S3.
   * If object is not present in S3, currently Access deined excpetion is shown
   * because of the permissions. S3 bucket is not public but only object is public so
   * Access denied exception is thrown.
   * This method handles the exception for 10 times for every 1000ms
   * Returns the Blob of S3
   * @param url
   */
  verifyS3URLisReady(url: string): Promise<string> {
    const options = {
      supressLogErrors: true,
    };
    return firstValueFrom(this.apiService.getBlobFromS3URL$(`${url}`, undefined, options).pipe(
      map((response) => response.url),
      catchError(() => { throw new Error('PDF Not Ready'); }),
      retryWhen((errors) => errors.pipe(delay(1000), take(10))),
    ));
  }
  /**
   * Returns the template info related to a given templateCode
   * @param templateCode The templateCode being requested
   */
  getTemplateData(templateCode: string): Observable<any> {
    const url = `${this.resource}/${templateCode}/template`;
    return this.apiService.get(`${url}`);
  }

  async updateTemplateData(codesAndValues: Array<any[]>) {
    const url = 'template-specs/updateTemplates';
    await this.apiService.put(`${url}`, codesAndValues).subscribe(
      (values) => {
        if (values.ok === 1) {
          console.log('Templates successfully updated. Number of records modified: ', JSON.stringify(values));
        }
      },
      (error) => {
        console.warn('An error has occured updating template values: ', error);
      },
    );
  }

  /**
   * Gets the templates related to a given product code
   * @param marketingOrderId
   * @param productCode The ProductCode to retrieve the templates for
   */
  getProductTemplates(marketingOrderId: string, productCode: string): Observable<TemplateInstance[]> {
    if (!this.productTemplateCache[marketingOrderId]) {
      // Initialize the product template cache for the given marketing order
      this.productTemplateCache[marketingOrderId] = {};
    }
    if (!this.productTemplateCache[marketingOrderId][productCode]) {
      // Filter down the available templates to the specific product code
      this.productTemplateCache[marketingOrderId][productCode] = this.apiService.get(`orders/${marketingOrderId}/${productCode}/templates`, undefined, { version: 'v2' }).pipe(
        map((templates) => templates.map((template) => this.addDefaultThumbnail(productCode, template))),
        shareReplay(1),
      );
    }
    // Return the product templates for the given marketing order
    return this.productTemplateCache[marketingOrderId][productCode];
  }

  addDefaultThumbnail(productCode: string, template: TemplateInstance): TemplateInstance {
    const defaultThumbnailURL = defaultURLMap[productCode];
    if (!template.thumbnailURL && defaultThumbnailURL) {
      template.thumbnailURL = defaultThumbnailURL;
    }
    return template;
  }

  /**
   * Removes temmplates that are not suitable for the order.
   * @param order
   * @param templates
   */
  filterTemplatesForOrder(order: MarketingOrder, templates: TemplateInstance[]) {
    return templates.filter((template) => this.isTemplateSuitableForOrder(order, template));
  }

  /**
   * Returns true if the selectedTemplate can be used for the order.
   *
   * Templates may be used for both global-luxury and regular coldwell-banker listings.
   *
   * If the order is a global-luxury listing and the template is flagged for global luxury then it is suitable.
   *
   * If the order is NOT a global luxury listing and the template is flagged as a coldwell-banker template then it is
   * suitable.
   *
   * @param order
   * @param selectedTemplate
   */
  isTemplateSuitableForOrder(order: MarketingOrder, selectedTemplate: TemplateInstance) {
    return (order && order.listing && selectedTemplate)
      && (
        // Print advertising templates can be used for any combo
        (selectedTemplate.productCode === ProductCode.PRINT_ADVERTISING)
        || (order?.listing?.globalLuxuryListing && selectedTemplate.globalLuxury) // flagged GL
        || (!order?.listing?.globalLuxuryListing && selectedTemplate.coldwellBanker) // flagged CB

        // The old condition to satisfy data prior to adding coldwell banker
        || (!order?.listing?.globalLuxuryListing && !selectedTemplate.globalLuxury && selectedTemplate.coldwellBanker === undefined)
      );
  }

  async setTemplateForSingleProduct(order: MarketingOrder, productCode: string) {
    const selectedProduct = order.selectedPackage.products.find((product) => product.code === productCode);
    // TODO: Call API for only the one selectedTemplateCode
    const templates = await this.getProductTemplates(order._id, productCode).pipe(
      map((productTemplates) => this.filterTemplatesForOrder(order, productTemplates)),
      take(1),
    ).toPromise();
    const selectedTemplate = templates.find((template) => template.code === selectedProduct.selectedTemplateCode);
    selectedProduct.selectedTemplate = selectedTemplate;

    if (selectedTemplate) {
      await this.orderService.selectTemplate(order, productCode, selectedTemplate);
    }

    return selectedProduct;
  }

  /**
   * Naming in case we want to add additional values to a template
   * on the fly. This can be done by adding to the promise chain.
   * Maybe move this out of the template service and into a directive?
   * @param templates
   * @returns
   */
  applyChangesToTemplates(templates): any {
    const promises = [];
    templates.forEach((template) => {
      promises.push(this.getTemplateData(template.code).toPromise());
    });
    return Promise.all(promises)
      .then(async (templateData) => {
        const templateUpdatePayload = [];
        templateData.forEach((template, index) => {
          // store previous value of approximate max chars for comparison
          const previousValue = template.templateInfo?.approximateMaxChars || null;
          template.pages.forEach((page) => {
            let bodies = page.pageItems.filter((item) => item.label.includes('body[1]'));
            if (bodies.length === 0) {
              bodies = page.pageItems.filter((pi) => pi.type === 'Group').map((p) => p.items).flat()
                .filter((item) => item.label.includes('body[1]'));
            }
            bodies.forEach((body) => {
              template.approximateMaxChars = this.approximateMaxChars(body);
            });
          });
          // Prepare payload to update template records.
          // Only add if an approximate max chars and the previous
          // values differ.
          if (
            template.approximateMaxChars
            && (template.approximateMaxChars !== previousValue)
          ) {
            templateUpdatePayload.push({
              code: templates[index].code,
              approximateMaxChars: template.approximateMaxChars,
            });
          }
        });
        if (templateUpdatePayload.length) {
          await this.updateTemplateData(templateUpdatePayload);
        }
        return templateData;
      });
  }

  /**
   * Creates dummy elements using a dummy string to approximate
   * the number of characters a text field will have in its associated
   * template
   * @param data
   * @returns
   */
  approximateMaxChars(data) {
    const element = this.generateElement(data);
    document.body.appendChild(element);

    let i = 0;
    element.innerHTML += `${DummyText[i]}`;
    i += 1;

    for (; i < DummyText.length; i++) {
      element.innerHTML += ` ${DummyText[i]}`;
      const pixelHeight = Math.round(data.rect.h);
      if (element.scrollHeight > pixelHeight) {
        break;
      }
    }

    const testLength = element.innerHTML.length;
    const lastWordLength = DummyText[i - 1].length;
    const maxChars = ((testLength - lastWordLength) / 10) * 10;
    // Remove the element as it is no longer needed
    document.body.removeChild(element);
    // Return updated approxMaxChar value if differs or null.
    return maxChars;
  }

  // Generates an element used for approximating the character count.
  generateElement(data) {
    const el: any = document.createElement('div', {});
    el.classList.add('generated-for-approximation');
    el.style = `visibility: hidden;
                width: ${Math.round(data.rect.w)}px;
                position: relative;
                font-size: ${data.text.pointSize}px;
                line-height: ${data.text.lineSpacing}px;
                text-rendering: geometricPrecision;
                text-size-adjust: none;
                margin: 0px!important;padding: 0px !important; border: none;`;

    return el;
  }
}
