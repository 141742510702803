<mat-drawer-container class="order-management-container h-100" autosize>
  <mat-drawer #sidenav class="sidenav" [opened]="!hideNavBar" [mode]="'side'">
    <div id="nav-container" class="h-100 clean-scroll-bar">
      <!-- Navigation Menu -->
      <lc-navigation-menu [links]="tabs$ | async" [isExpanded]="isExpanded">
      </lc-navigation-menu>
    </div>
  </mat-drawer>

  <div class="fx-column h-100">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
