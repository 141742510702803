import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
  FeatureFlags,
  LaunchDarklyService,
  ListingPhoto,
  MarketingOrder,
  MarketingOrderService,
  PromptDialogService,
  ThumbnailSizes,
  ToasterService,
  UIConstants,
  UpdateCurrentOrderState,
  PhotoForm,
} from '@lc/core';
import { Observable, Subject } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { LoadingEvent } from '@lc/ui';
import { PhotoViewModel } from '../../photos/photo-view-model';
import { slideAnimation } from './slide.animation';
import { OrderPhotoService } from '../../../../../order-management/src/lib/services/order-photo.service';

export class PhotoGalleryDialogData {
  constructor(
    readonly title: string,
    readonly photos: ListingPhoto[],
    readonly selectedPhotoIndex: number,
    readonly marketingOrder: MarketingOrder,
    readonly canDelete: boolean,
    readonly canFavorite: boolean,
    readonly canDownload: boolean,
  ) {
  }
}

@Component({
  selector: 'lc-photo-gallery-dialog',
  templateUrl: './photo-gallery-dialog.component.html',
  styleUrls: ['./photo-gallery-dialog.component.scss'],
  animations: [slideAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoGalleryDialogComponent implements OnInit {
  readonly title: string;
  photos: ListingPhoto[];
  selectedIndex = 0;
  marketingOrder: MarketingOrder;
  canDelete: boolean = false;
  canFavorite: boolean = false;
  canDownload: boolean = true;
  thumbnailSize = ThumbnailSizes.LARGE_THUMBNAIL_WIDTH;
  currentIndex = 0;
  aiFeatureIsEnabled$: Observable<boolean>;
  photoForms: PhotoForm[];
  readonly spinner = LoadingEvent.none;
  private readonly _close = new Subject();
  readonly close = this._close.asObservable();
  @Input() photoViewModels: PhotoViewModel[];
  @Output() photosUpdated = new EventEmitter<ListingPhoto[]>();
  disableSave = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhotoGalleryDialogData,
    private marketingOrderService: MarketingOrderService,
    private store: Store<any>,
    private promptDialog: PromptDialogService,
    private toasterService: ToasterService,
    private launchDarklyService: LaunchDarklyService,
    private photoService: OrderPhotoService,
  ) {
    this.title = data.title;
    this.photos = data.photos;
    this.selectedIndex = data.selectedPhotoIndex ?? this.selectedIndex;
    this.marketingOrder = data.marketingOrder ?? this.marketingOrder;
    this.canDelete = data.canDelete ?? this.canDelete;
    this.canFavorite = data.canFavorite ?? this.canFavorite;
    this.canDownload = data.canDownload ?? this.canDownload;

    this.aiFeatureIsEnabled$ = this.launchDarklyService.getFeature$(FeatureFlags.AI_IMAGE_TAGGING, false);
    this.photoForms = this.photos.map((photo: any) => new PhotoForm(photo));
  }

  ngOnInit() {
    this.createPhotoViewModels();
    this.preloadImages();
    this.photoForms.forEach((form) => form.valueChanges.subscribe(() => this.checkIfAnyFormIsDirty()));
  }

  /** Closes the dialog by emitting the close event */
  onClose() {
    this._close.next(undefined);
  }

  async onSave() {
    const updates = this.photoForms.map((form) => form.getValue({ removeNulls: true, onlyDirty: true }));
    const isUpdated = updates.some((update) => Object.keys(update).length);

    if (isUpdated) {
      const updatedPhotos = this.photos.map((photo, index) => ({
        ...photo,
        ...updates[index],
      }));

      this.photoService.patchOrderPhotos(this.marketingOrder._id, updatedPhotos)
        .then((marketingOrder) => {
          this.store.dispatch(UpdateCurrentOrderState({ payload: marketingOrder }));
          this.toasterService.showInfo('Photos saved successfully');
        })
        .catch((error) => this.toasterService.showError(error));
    }
    this.onClose();
  }

  preloadImages() {
    this.photos.forEach((slide) => {
      (new Image()).src = slide.uri;
    });
  }

  setCurrentSlideIndex(index) {
    this.selectedIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.selectedIndex === index;
  }

  prevSlide() {
    if (this.selectedIndex > 0) {
      this.selectedIndex -= 1;
    } else {
      this.selectedIndex = this.photos.length - 1;
    }
  }

  nextSlide() {
    if (this.selectedIndex < this.photos.length - 1) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex = 0;
    }
  }

  createPhotoViewModels() {
    this.photoViewModels = this.photos.map((photo) => {
      const viewModel = new PhotoViewModel(photo);
      viewModel.canFavorite = this.canFavorite;
      viewModel.canDownload = this.canDownload;
      viewModel.canDelete = this.canDelete;
      return viewModel;
    });
  }

  async handleDelete(viewModel: PhotoViewModel) {
    const message = 'One or more products may be using this photo. <br> Are you sure you want to delete?';
    this.promptDialog
      .openPrompt(UIConstants.CONFIRM, message, UIConstants.YES, [UIConstants.CANCEL])
      .then(async (action: { text: UIConstants }) => {
        if (action?.text !== UIConstants.YES) {
          return;
        }
        const remainingPhotos = this.photoViewModels.filter((x) => x.photo._id !== viewModel.photo._id).map((display) => display.photo);
        await this.marketingOrderService.setPhotos(this.marketingOrder, remainingPhotos);

        // Update local state
        this.photoViewModels = this.photoViewModels.filter((x) => x.photo._id !== viewModel.photo._id);
        this.photos = this.photos.filter((photo) => photo._id !== viewModel.photo._id);
        this.photoForms.splice(this.selectedIndex, 1);

        // If the currently selected photo was deleted, adjust the selectedIndex
        if (this.selectedIndex >= this.photos.length) {
          this.selectedIndex = this.photos.length - 1;
        }

        // Emit the updated photos
        this.photosUpdated.emit(this.photos);
      });
  }

  async handleToggleFavorites(viewModel: PhotoViewModel) {
    viewModel.photo.favorite = !viewModel.photo.favorite;
    await this.marketingOrderService.updatePhoto(
      this.marketingOrder._id,
      viewModel.photo._id,
      viewModel.photo,
    ).catch((error) => {
      this.toasterService.showError(error?.error?.error?.message);
      viewModel.photo.favorite = !viewModel.photo.favorite;
    });
  }

  checkIfAnyFormIsDirty() {
    this.disableSave = !this.photoForms.some((form) => form.dirty);
  }

  protected readonly ThumbnailSizes = ThumbnailSizes;
}
