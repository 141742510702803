import { Component } from '@angular/core';
import { AppInitializerService, AppService, UserAgentService } from '@lc/core';

@Component({
  selector: 'lc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    appInitializer: AppInitializerService,
    userAgentService: UserAgentService,
  ) {
    userAgentService.doBrowserCheck();
    appInitializer.initializeKeys('lc-admin-app', AppService.productSuiteName);
  }
}
