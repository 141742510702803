import { Component, OnInit } from '@angular/core';
import { FeatureFlags, LaunchDarklyService } from '@lc/core';
import { NavigationMenuItem } from '@lc/ui';
import { Observable, map, shareReplay } from 'rxjs';

@Component({
  selector: 'lc-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent implements OnInit {
  public isExpanded = true;
  public hideNavBar = false;

  readonly tabs$: Observable<NavigationMenuItem[]>;

  constructor(private launchDarklyService: LaunchDarklyService) {
    const isSuperAdmin$ = this.launchDarklyService.getFeature$(FeatureFlags.SUPER_ADMIN).pipe(shareReplay(1));

    this.tabs$ = isSuperAdmin$.pipe(
      map((isSuperAdmin) => this.getTabs(isSuperAdmin)),
    );
  }

  ngOnInit() {
    if (window.location.pathname.includes('/indesign/intake')) {
      this.hideNavBar = true;
    }
  }

  private getTabs(isSuperAdmin: boolean) {
    // Since we want don't want to mutate `this.defaultNavigationTabs`
    // we will use the spread operator to create a new reference.
    const tabs = [...this.defaultNavigationTabs];

    if (isSuperAdmin) {
      tabs.push(
        new NavigationMenuItem(
          'Store',
          '/store',
          'store',
          [
            new NavigationMenuItem('Orders', '/store/orders', 'tag'),
            new NavigationMenuItem('Payments', '/store/payments', 'receipt'),
            new NavigationMenuItem('Customers', '/store/customers', 'users'),
            new NavigationMenuItem('Tax Codes', '/store/tax-codes', 'tag'),
          ],
        ),
      );
    }

    return tabs;
  }

  // TODO: Tabs need to support nested routes
  readonly defaultNavigationTabs: NavigationMenuItem[] = [
    new NavigationMenuItem('Dashboard', '/', 'home', [], { exactMatch: true }),
    new NavigationMenuItem('Specs and Offerings', '/data-setup', 'settings', [
      new NavigationMenuItem('Specs and Offerings', '/data-setup'),
      new NavigationMenuItem('Template Intake', '/templates/pending', 'library_add'),
      new NavigationMenuItem(
        'Template Offering Intake',
        '/templates/offerings/pending',
        'library_add',
      ),
      new NavigationMenuItem('Offer Templates', '/templates/offer', 'search'),
    ]),
    new NavigationMenuItem('Photography Service', '/photography/list', 'camera_alt'),
    new NavigationMenuItem('Orders', '/orders', 'view_list', [
      new NavigationMenuItem('Order Lookup', '/orders', 'view_list', [], { exactMatch: true }),
      new NavigationMenuItem('Order Details', '/orders/details', 'list_alt'),
    ]),
    new NavigationMenuItem('Manage Users', '/users', 'people_outline'),
    new NavigationMenuItem('Email Templates', '/emailtemplates', 'email'),
    new NavigationMenuItem('Indesign Template', '/indesign/intake', 'library_add'),
    new NavigationMenuItem('Regenerate Pdf', '/regeneratepdf', 'search'),
    new NavigationMenuItem('Surveys', '/surveys', 'add_reaction', [
      new NavigationMenuItem('Manage Surveys', '/surveys', 'add_reaction', [], {
        exactMatch: true,
      }),
      new NavigationMenuItem('Reports', '/surveys/reporting', 'poll'),
    ]),
    new NavigationMenuItem('Reports', '/reporting', 'analytics', [
      new NavigationMenuItem('Status Time Tracking', '/reporting/status-time-tracking', 'schedule', [], {
        exactMatch: true,
      }),
      new NavigationMenuItem('CBA Usage Report', '/reporting/cba-usage-report', 'schedule', [], {
        exactMatch: true,
      }),
    ]),
  ];
}
