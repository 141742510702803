import { Injectable } from '@angular/core';
import * as snakeCase from 'lodash.snakecase';
import { from, Observable } from 'rxjs';
import {
  delay, map, retryWhen, switchMap, take,
} from 'rxjs/operators';
import {
  Address, MarketingOrder, OutputMimeType, ProductCategory, ProductCode, ProductInstance,
} from '../models';
import { ContentGenerator } from './content-generator';
import { ApiService } from './api.service';

enum PrintQuality {
  proof = 'proof',
  print = 'print',
}

enum ImageResolution {
  high = 'high',
  low = 'low',
}

@Injectable()
export class PdfGenerationService implements ContentGenerator {
  readonly downloadable = true;
  readonly mimeType = OutputMimeType.pdf;

  constructor(private apiService: ApiService) {
  }

  /** Requests the product template for the marketing order and downloads it to the local file system  */
  download(order: MarketingOrder, product: ProductInstance, imageResolution?: string, isDownload?: boolean): Observable<any> {
    return from(this.downloadPdf$(order._id, order.listing.address, product, imageResolution, isDownload).toPromise());
  }

  /** Requests the product template for the marketing order and downloads it to the local file system  */
  downloadById(marketingOrderId: string, listingAddress: Address, product: ProductInstance) {
    this.downloadPdf$(marketingOrderId, listingAddress, product).toPromise();
  }

  /*
     Leaves the original implementation behaving as it did before.
   */
  generate(order: MarketingOrder, product: ProductInstance, otherData?: { consent?: boolean, preview?: boolean }): Observable<any> {
    return this.generatePdf(order, product, otherData, ImageResolution.high, PrintQuality.print);
  }

  /**
   * Requests the product template for the marketing order and returns it as an in-memory blob
   */
  generatePdf(order: MarketingOrder, product: ProductInstance, otherData?: { consent?: boolean, preview?: boolean }, imageResolution = ImageResolution.high, printQuality = PrintQuality.print): Observable<Blob> {
    const fileName = this.getFileName(order?.listing?.address, product);
    return this.getS3URL$(order._id, product, fileName, imageResolution, printQuality, otherData?.preview).pipe(switchMap(
      (response) => {
        const actualURl = response.s3URL;// +"?t=" + Date.now();
        return this.getBlobFromS3$(actualURl).pipe(
          map((blobResponse) => {
            if (blobResponse.status === 200 && blobResponse.headers.get('Content-Type') === 'application/json') {
              throw new Error('PDF Not Ready');
            } else {
              return blobResponse.body;
            }
          }),
          retryWhen((errors) => errors.pipe(delay(1000), take(60))),
        );
      },
    ));
  }

  /**
   * Retrieves the PDF via the API Service and downloads the file to the local file system.
   * NOTE: It is important to go through the API service or HttpClient as it will ensure the proper
   * Bearer token gets sent via the token.interceptor.ts
   */
  private downloadPdf$(
    marketingOrderId: string,
    listingAddress: Address,
    product: ProductInstance,
    imageResolution: string = 'high',
    isDownload: boolean = false,
  ) {
    const fileName = this.getFileName(listingAddress, product);
    console.warn(`Inside downloadPdf$ : ${fileName}, OrderId: ${marketingOrderId}`);
    return this.getS3URL$(marketingOrderId, product, fileName, imageResolution, PrintQuality.print, null, isDownload).pipe(switchMap(
      (response) => {
        const actualURl = response.s3URL;// .substring(0, response.s3URL.indexOf('?'));
        return this.getBlobFromS3$(actualURl).pipe(
          map((blobResponse) => {
            if (blobResponse.status === 200 && blobResponse.headers.get('Content-Type') === 'application/json') {
              throw new Error('PDF Not Ready');
            } else {
              return this.apiService.downloadFile(blobResponse.body, fileName);
            }
          }),
          retryWhen((errors) => errors.pipe(delay(1000), take(60))),
        // map(response => this.apiService.downloadFile(response.body, fileName))
        );
      },
    ));
  }

  /** Gets the appropriate fileName based on the given product code and listing address */
  private getFileName(listingAddress: Address, product: { title: string, code: string }) {
    // Sanitize advertising file name so it does not contain the string "advertising".
    // We found that having this word in the url can cause ad blockers to block the request.
    const title = snakeCase(product.title.toLowerCase().replace(/advertising/g, 'adv'));
    if (ProductCode.SOCIAL_MEDIA_BANNER === product.code) {
      return `${title}_${snakeCase(listingAddress.streetAddress1)}.jpg`.toLowerCase();
    }
    return `${title}_${snakeCase(listingAddress.streetAddress1)}.pdf`.toLowerCase();
  }

  private getBlobFromS3$(s3URL: string) {
    // TODO: Convert the url parameters to query parameters. The current route does not follow REST APIs well
    return this.apiService.getBlobFromS3URL$(s3URL);
  }

  /**
   * checks to make sure we are rendering a pdf for a printable product.
   * This is an added check to log what is going on when this occurs with the
   * Television and Video product.
   *
   * @param orderId
   * @param product
   */
  checkProductIsPrintable(orderId: string, product: ProductInstance) {
    if (product.category && product.category !== ProductCategory.PRINT) {
      // tslint:disable-next-line: no-console
      console.log(`Order: ${orderId}, an attempt to render PDF for a non-printable product has been made - productCode: ${product.code}`);
    } else if (product.code && product.code === ProductCode.TELEVISION_AND_VIDEO) {
      // tslint:disable-next-line: no-console
      console.log(`Order: ${orderId}, an attempt to render PDF for television and video has been made - productCode: ${product.code}`);
    }
  }

  private getS3URL$(marketingOrderId: string, product: ProductInstance, fileName: string, imageResolution: string = 'high', quality: PrintQuality = PrintQuality.print, preview?: boolean, isDownload: boolean = false) {
    this.checkProductIsPrintable(marketingOrderId, product);
    // TODO: Convert the url parameters to query parameters. The current route does not follow REST APIs well
    const url = `/print-render/order/${marketingOrderId}/${product.code}/${quality}/${fileName}`;
    const queryParams = {} as any;
    queryParams.imageResolution = imageResolution === 'low' ? imageResolution : 'high';
    queryParams.isDownload = isDownload;
    if (preview) {
      queryParams.preview = true;
    }
    return this.apiService.get(url, queryParams);
  }

  // To Regenerate the pdf from Admin view
  regeneratePdf(marketingOrderId: string | string[], productCode: string, fileName: string, imageResolution: string = 'high', quality: PrintQuality = PrintQuality.print) {
    const url = `/regenerate-pdf/order/${productCode}/${quality}/${fileName}`;
    const payload = {
      marketingOrderIds: marketingOrderId,
      productCode,
      fileName,
      imageResolution,
    };
    return this.apiService.post(url, payload);
  }
}
